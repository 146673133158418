import React from 'react';
import styled from 'styled-components';
const IconS = styled.i`
	font-size: ${(props) => props.fs || '2em'};
	color: ${(props) => props.theme[props.color] || props.theme.secondary};
	cursor: pointer;
	width: ${(props) => props.width};
	margin: ${(props) => props.margin};
	transition: 0.2s;
	&:hover {
		color: ${(props) => props.theme.main};
		transition: 0.2s;
	}
`;

const Icon = ({ icon, ...rest }) => {
	return <IconS className={icon} {...rest} />;
};

export default Icon;
