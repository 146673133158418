export const theme = {
	transparent: 'transparent',
	shadow25: 'rgba(0,0,0,0.25)',
	shadow50: 'rgba(0,0,0,0.5)',
	shadow: 'rgba(0,0,0,1)',
	white: 'rgba(255,255,255,1)',
	white50: 'rgba(255,255,255,0.5)',
	main: 'rgba(68,35,96,1)',
	main50: 'rgba(68,35,96,0.5)',   
	secondary: 'rgba(213,40,151,1)',
	secondary50: 'rgba(213,40,151,0.5)',
	red: 'rgba(230,37,59,1)',
	red50: 'rgba(230,37,59,0.5)',
	green: 'rgba(28,211,84,1)',
	green50: 'rgba(28,211,84,0.5)',
	yellow: 'rgba(230, 187, 37,1)',
	yellow50: 'rgba(230, 187, 37,0.5)',
	lightblue: 'rgba(37, 95, 230,1)',
	lightblue50: 'rgba(37, 95, 230,0.5)',
};
