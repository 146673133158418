import styled from 'styled-components';

export const DashboardWrapper = styled.div`
	width: 100%;
	height: 100%;
	background: ${(props) => props.theme.main};
	background: linear-gradient(170deg, rgba(213, 40, 151, 1) 0%, rgba(68, 35, 96, 1) 100%);
	display: flex;
	justify-content: center;
	align-items: center;
	
`;

export const ContainerDashboard = styled.div`
	width: 75%;
	height: 100%;
	background-color: white;
	box-shadow: 0 0 1em ${(props) => props.theme.shadow25};
	padding: 4.25% 0 2.5em 0;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	align-items: center;
	@media screen and (max-width: 700px) {
		width: 95%;
		padding: 10% 0 1em 0;
	}
`;

export const WrapperRoutes = styled.div`
	height: 75%;
	width: 85%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	@media screen and (max-width: 700px) {
		width: 100%;
		font-size:0.8em
	}
`;

export const TitleRoute = styled.span`
	font-size: 1.75em;
	color: ${(props) => props.theme.main};
	font-weight: bold;
	height: 10%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: uppercase;
`;

export const RoutesContainer = styled.div`
	height: 90%;
	width: 100%;
`;
