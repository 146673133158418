import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { ToastContext } from '../../context/ToastContext';
import { startLoading, stopLoading } from '../../redux/loader/loader.actions';
import { definirNuevaContrasena } from '../../services/Auth';
import ButtonVEME from '../ButtonVEME/ButtonVEME';
import InputVEME from '../InputVEME/InputVEME';

const Wrapper = styled.form`
	width: 100%;
	height: 100%;
`;

const Row = styled.div`
	margin: ${(props) => props.margin || '1.5em 0em 2em 0em'};
	display: flex;
`;

const ModalCambiarContrasena = ({ onHide }) => {
	const dispatch = useDispatch();
	const { toast } = useContext(ToastContext);
	const [showPassword, setShowPassword] = useState(false);
	const [credenciales, setCredenciales] = useState({
		password: '',
		checkPassword: '',
	});

	const onSubmit = async (e) => {
		e.preventDefault();
		try {
			if (credenciales.password !== credenciales.checkPassword) {
				return toast.current.show({
					severity: 'error',
					summary: 'Las contraseñas ingresadas deben coincidir.',
				});
			}
			dispatch(startLoading('Guardando nueva contraseña'));

			const response = await definirNuevaContrasena(credenciales.password);
			if (response.Success) {
				toast.current.show({
					severity: 'success',
					summary: 'Tu contraseña se ha modificado correctamente.',
				});
			} else {
				toast.current.show({
					severity: 'error',
					summary: 'Hubo un error al modificar tu contraseña.',
				});
			}
			dispatch(stopLoading());
			onHide();

			setCredenciales({
				password: '',
				checkPassword: '',
			});
		} catch ({ Error }) {
			dispatch(stopLoading());
			toast.current.show({
				severity: 'error',
				summary: 'Hubo un error al modificar tu contraseña.',
			});
		}
	};
	return (
		<Wrapper onSubmit={onSubmit}>
			<Row>
				<InputVEME
					label='Nueva contraseña'
					value={credenciales.password}
					onChange={(e) => setCredenciales({ ...credenciales, password: e.target.value })}
					type={!showPassword ? 'password' : 'text'}
					righticon
					icon={!showPassword ? 'pi-eye' : 'pi-eye-slash'}
					onClickIcon={() => setShowPassword(!showPassword)}
				/>
			</Row>
			<Row>
				<InputVEME
					label='Confirmar contraseña'
					value={credenciales.checkPassword}
					onChange={(e) => setCredenciales({ ...credenciales, checkPassword: e.target.value })}
					type={!showPassword ? 'password' : 'text'}
					righticon
					icon={!showPassword ? 'pi-eye' : 'pi-eye-slash'}
					onClickIcon={() => setShowPassword(!showPassword)}
				/>
			</Row>
			<Row style={{ display: 'flex', justifyContent: 'flex-end' }} margin='0'>
				<ButtonVEME
					label='Guardar'
					disabled={Object.keys(credenciales).filter((key) => !credenciales[key]).length}
				/>
			</Row>
		</Wrapper>
	);
};

export default ModalCambiarContrasena;
