import { AxiosInstance } from '../utils/AxiosInstance';

export const obtenerAmenitiesConsorcio = async (consorcio) => {
	const { data } = await AxiosInstance.post(`/Servicios/AmenitiesObtener?IdConsorcio=${consorcio}`);
	return data;
};

export const obtenerReservasAmenities = async (ID) => {
	const { data } = await AxiosInstance.post(`/Servicios/AmenitiesObtenerReservas?IdConsorcio=${ID}`);
	return data;
};

export const obtenerOpcionesAmenity = async ({ fecha, IdAmenity }) => {
	const { data } = await AxiosInstance.post(
		`/Servicios/AmenityOpcionesObtener?IdAmenity=${IdAmenity}&fecha=${fecha}`,
	);
	return data;
};

export const guardarNuevaReserva = async ({ fecha, IdUnidadFuncional, opcion, aceptaCargo, telefono }) => {
	const { data } = await AxiosInstance.post(
		`/Servicios/AmenityReservar?idOpcion=${opcion}&fecha=${fecha}&telefono=${telefono}&aceptaCargo=${aceptaCargo}&idUnidadFuncional=${IdUnidadFuncional}`,
	);
	return data;
};
