import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import Login from './Login';
import { AuthenticationWrapper, AuthenticationContainer, ImageWrapper } from './styled';
import login_image from '../../assets/images/Login.svg';
import recover_image from '../../assets/images/Forgot.svg';
import { useSelector } from 'react-redux';
import { ToastContext } from '../../context/ToastContext';
import RecoverPassword from './RecoverPassword';
const Authentication = () => {
	const { auth } = useSelector((state) => state);
	const { toast } = useContext(ToastContext);
	const navigate = useNavigate();
	useEffect(() => {
		if (auth?.user) {
			toast.current.show({
				severity: 'warn',
				summary: 'Ya tenes una sesión iniciada',
			});
			navigate('/principal');
		}
	}, []);

	return (
		<AuthenticationWrapper>
			<AuthenticationContainer>
				<Routes>
					<Route index path='/' element={<Navigate to={'iniciar-sesion'} />} />
					<Route
						path='iniciar-sesion'
						element={
							<>
								<Login />
								<ImageWrapper image={login_image} />
							</>
						}
					/>
					<Route
						path='recuperar-credenciales'
						element={
							<>
								<RecoverPassword />
								<ImageWrapper image={recover_image} />
							</>
						}
					/>
				</Routes>
			</AuthenticationContainer>
		</AuthenticationWrapper>
	);
};

export default Authentication;
