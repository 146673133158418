import { PrimeIcons } from 'primereact/api';
import React from 'react';
import styled from 'styled-components';
const Footer = () => {
	return (
		<FooterWrapper>
			<TyC>Términos y Condiciones</TyC>
			<PoweredBy>Powered by Expentrack - Todos los derechos reservados 2022</PoweredBy>
			<SocialMedia>
				<Icon as='a' href='https://www.expentrack.com/' className={PrimeIcons.GLOBE} target='_blank' />
				<Icon
					as='a'
					href='https://www.instagram.com/expentrack/'
					className={PrimeIcons.INSTAGRAM}
					target='_blank'
				/>
				<Icon
					as='a'
					href='https://www.facebook.com/expentrack'
					className={PrimeIcons.FACEBOOK}
					target='_blank'
				/>
			</SocialMedia>
		</FooterWrapper>
	);
};

export default Footer;

const FooterWrapper = styled.div`
	width: 100%;
	height: 2.5em;
	background-color: ${(props) => props.theme.white};
	position: absolute;
	bottom: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: space-around;
	text-align: center;
	font-size: 0.75em;
	box-shadow: 0 0 0.5em ${(props) => props.theme.shadow25};
	@media screen and (max-width: 700px) {
		height: 5em;
	  }
`;

const TyC = styled.span``;
const PoweredBy = styled.span`
	color: ${(props) => props.theme.main50};
	@media screen and (max-width: 700px) {
		font-size: 0.8em;
	}
`;
const SocialMedia = styled.div``;
const Icon = styled.i`
	margin: 0 0.5em;
	font-size: 1.5em;
	color: ${(props) => props.theme.secondary};
	cursor: pointer;
	@media screen and (max-width: 700px) {
		margin: 0.1em
	  }
`;
