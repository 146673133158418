import { PrimeIcons } from 'primereact/api';
import React from 'react';
import styled from 'styled-components';

const Container = styled.label`
	background-color: ${(props) => (props.value ? props.theme.transparent : props.theme.main)};
	border: 3px solid ${(props) => (props.value ? props.theme.main : props.theme.transparent)};
	color: ${(props) => (props.value ? props.theme.main : props.theme.white)};
	padding: 1em 3em;
	border-radius: 0.5em;
	font-weight: bold;
	cursor: pointer;
	display: flex;
	align-items: center;
	transition: 0.2s;
	& > input {
		display: none;
	}
`;

const Icon = styled.i`
	font-size: 1.25em;
	margin-right: 0.75em;
`;

const InputFile = ({ value, onChange, label, accept }) => {
	return (
		<Container value={value}>
			<Icon className={value ? PrimeIcons.CHECK : PrimeIcons.UPLOAD} />
			{label}
			<input type='file' accept={accept} onChange={onChange} />
		</Container>
	);
};

export default InputFile;
