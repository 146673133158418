import { Tooltip } from 'primereact/tooltip';
import React from 'react';
import styled from 'styled-components';
const StyledTooltip = styled(Tooltip)`
	& > .p-tooltip-text {
		background-color: ${(props) => props.theme.main} !important;
	}
	& > .p-tooltip-arrow {
		border-right-color: ${(props) => props.theme.main} !important;
	}
	
`;
const TooltipVEME = ({ ...rest }) => {
	return <StyledTooltip {...rest} />;
};

export default TooltipVEME;
