import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { persistReducer, persistStore } from 'redux-persist';
import { applyMiddleware, createStore } from 'redux';
import rootReducer from './root-reducer';
import { composeWithDevTools } from 'redux-devtools-extension';

const middlewares = [thunk];

const INITIAL_STATE = {};

const PERSIST_CONFIG = {
	key: 'auth',
	storage,
	whitelist: ['auth'],
	transforms: [
		encryptTransform({
			secretKey: process.env.REACT_APP_SECRET_ENCRYPT,
		}),
	],
};

const presistReduc = persistReducer(PERSIST_CONFIG, rootReducer);

export const store = createStore(presistReduc, INITIAL_STATE, composeWithDevTools(applyMiddleware(...middlewares)));
export const persistor = persistStore(store);
