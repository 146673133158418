import axios from 'axios';

export const AxiosInstance = axios.create({
	baseURL: process.env.REACT_APP_VEME_URL,
});

AxiosInstance.interceptors.request.use((config) => {
	const token = localStorage.getItem('authorization');
	if (token) {
		config.headers.Authorization = token;
	}
	return {
		...config,
		Authorization: token ? token : undefined,
	};
});

AxiosInstance.interceptors.response.use(
	(response) => {
		if (response.status === 401) {
			alert('You are not authorized');
		}
		return response;
	},
	(error) => {console.log(error);
		if (error.response && error.response.data) {
			return Promise.reject(error.response.data);
		}
		return Promise.reject(error.message);
	},
);
