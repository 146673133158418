import { AxiosInstance } from '../utils/AxiosInstance';

export const Login = async ({ username, password }) => {
	const body = {
		Usuario: username,
		Clave: password,
		ClavePublica: process.env.REACT_APP_PUBLIC_KEY,
	};
	const { data } = await AxiosInstance.post('/login/iniciarSesion', body);

	return data;
};

export const GetUserInformation = async () => {
	const { data } = await AxiosInstance.post('/servicios/obtenerusuario');

	return data;
};

export const regenerarContrasenia = async (email) => {
	const { data } = await AxiosInstance.post(`/Login/RegenerarClave?email=${email}`);

	return data;
};

export const definirNuevaContrasena = async (password) => {
	const { data } = await AxiosInstance.post(`/Servicios/CambiarClave?clave=${password}`);
	return data;
};
