import React from 'react';
import styled from 'styled-components';
import { Button } from 'primereact/button';

const ButtonVEME = ({ label, onClick, ...rest }) => {
	return <ButtonStyled onClick={onClick} label={label} {...rest} />;
};

export default ButtonVEME;

const ButtonStyled = styled(Button)`
	background-color: ${(props) => (!props.secondary ? props.theme.main : props.theme.transparent)} !important;
	border: 2px solid ${(props) => (props.secondary ? props.theme.secondary : props.theme.transparent)};
	color: ${(props) => (props.secondary ? props.theme.secondary : props.theme.white)} !important;
	font-size: ${(props) => (props.small ? '0.85em' : null)};
	width: ${(props) => (props.small ? '10em' : '12.5em')} !important;
	height: ${(props) => props.height || '100%'};
	&:hover {
		background-color: ${(props) => (props.secondary ? props.theme.main : props.theme.transparent)} !important;
		border: 2px solid ${(props) => (!props.secondary ? props.theme.secondary : props.theme.transparent)} !important;
		color: ${(props) => (!props.secondary ? props.theme.secondary : props.theme.white)} !important;
	}
	@media screen and (max-width: 700px) {
		width:100%!important;
		text-align: center;
	}
`;
