import { Accordion, AccordionTab } from 'primereact/accordion';
import { PrimeIcons } from 'primereact/api';
import React, { useState } from 'react';
import { useLayoutEffect } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonVEME from '../../../components/ButtonVEME/ButtonVEME';
import Empty from '../../../components/Empty/Empty';
import Icon from '../../../components/Icon/Icon';
import InputVEME from '../../../components/InputVEME/InputVEME';
import Modal from '../../../components/Modal/Modal';
import Pill from '../../../components/Pill/Pill';
import { startLoading, stopLoading } from '../../../redux/loader/loader.actions';
import { obtenerPedidosSugerencias, obtenerTiposPedido } from '../../../services/PedidosSugerencias';
import { setColorByStatus } from '../../../utils/Functions';
import DetallePedido from './DetallePedido';
import NuevoPedido from './NuevoPedido';
import {
	HeaderPedidosSugerencias,
	HistoryPedidosSugerencias,
	InputContainer,
	WrapperPedidosSugerencias,
} from './styles';

const PedidosSugerencias = () => {
	const dispatch = useDispatch();
	const { usuario, consorcio, unidadfuncional, user } = useSelector((state) => state.auth);

	const [date, setDate] = useState(new Date());

	const [modal, setModal] = useState({ visible: false });

	const [pedidos, setPedidos] = useState([]);
	const [tiposPedidos, setTiposPedidos] = useState([]);

	const Header = (pedido) => {
		return (
			<div style={{ display: 'flex', alignItems: 'center' }}>
				{pedido.Fecha} - {pedido?.TipoPedido?.Descripcion} - {pedido.UnidadCarga?.DescripcionCorta} -{' '}
				<Pill color={setColorByStatus(pedido.Estado.Codigo)} status={pedido.Estado.Descripcion} />
			</div>
		);
	};

	const ListarPedidosSugerencias = async () => {
		try {
			dispatch(startLoading('Obteniendo pedidos y sugerencias'));
			const { Success: SuccessTipos, Data: DataTipos } = await obtenerTiposPedido();

			if (SuccessTipos) {
				setTiposPedidos(DataTipos);
			}

			const response = await obtenerPedidosSugerencias({
				consorcio,
				usuario,
				mes: date.getMonth() + 1,
				anio: date.getFullYear(),
			});

			if (response.Success) {
				setPedidos(response.Data);
			}

			dispatch(stopLoading());
		} catch ({ Error }) {
			dispatch(stopLoading());
		}
	};

	useEffect(() => {
		ListarPedidosSugerencias();
	}, []);

	useEffect(() => {
		ListarPedidosSugerencias();
	}, [date, consorcio, unidadfuncional]);

	return (
		<WrapperPedidosSugerencias>
			<Modal
				footer={NaN}
				header='Nuevo pedido o sugerencia'
				width={'60vw'}
				visible={modal.visible}
				onHide={(e) => setModal({ ...modal, visible: false })}>
				<NuevoPedido
					onHide={(e) => setModal({ ...modal, visible: false })}
					tiposPedido={tiposPedidos}
					user={user}
					consorcio={consorcio}
					unidadfuncional={unidadfuncional}
				/>
			</Modal>
			<HeaderPedidosSugerencias>
				<InputContainer>
					<InputVEME
						label='Período'
						isCalendar
						view='month'
						dateFormat='mm/yy'
						yearNavigator
						value={date}
						onChange={(e) => setDate(e.value)}
						yearRange={`${new Date().getFullYear() - 1}:${new Date().getFullYear() + 1}`}
					/>
				</InputContainer>

				<ButtonVEME height='60%' label='Agregar' onClick={(e) => setModal({ ...modal, visible: true })} />
			</HeaderPedidosSugerencias>
			<HistoryPedidosSugerencias>
				{pedidos.length > 0 ? (
					<Accordion style={{ width: '100%' }}>
						{pedidos.map((pedido) => {
							return (
								<AccordionTab header={Header(pedido)}>
									<DetallePedido pedido={pedido} />
								</AccordionTab>
							);
						})}
					</Accordion>
				) : (
					<Empty message={`No hay pedidos | sugerencias para mostrar`} />
				)}
			</HistoryPedidosSugerencias>
		</WrapperPedidosSugerencias>
	);
};

export default PedidosSugerencias;
