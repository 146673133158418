import React from 'react';
import styled from 'styled-components';
import Pill from '../../../components/Pill/Pill';
import { Capitalize, setColorByStatus } from '../../../utils/Functions';

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const RowPedido = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	color: ${(props) => props.theme.main};
	margin: 1em 0em;
	justify-content: space-around;
`;

const LabelPedido = styled.span`
	font-size: 0.75em;
`;

const ValuePedido = styled.span`
	font-weight: bold;
`;

const Column = styled.div`
	width: 30%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const DetallePedido = ({ pedido }) => {
	return (
		<Wrapper>
			<RowPedido>
				<Column>
					<LabelPedido>Consorcio:</LabelPedido>
					<ValuePedido>{Capitalize(pedido.Consorcio.Descripcion)}</ValuePedido>
				</Column>
				<Column>
					<LabelPedido>Unidad funcional:</LabelPedido>
					<ValuePedido>{pedido.UnidadCarga.DescripcionCorta}</ValuePedido>
				</Column>
				<Column>
					<LabelPedido>Unidad funcional afectada:</LabelPedido>
					<ValuePedido>
						{pedido?.UnidadAfectada.Id !== pedido?.UnidadCarga?.Id
							? pedido.UnidadAfectada.DescripcionCorta
							: 'N/C'}
					</ValuePedido>
				</Column>
			</RowPedido>
			<RowPedido>
				<Column>
					<LabelPedido>Solicitante:</LabelPedido>
					<ValuePedido>{Capitalize(pedido.Usuario.Value)}</ValuePedido>
				</Column>
				<Column>
					<LabelPedido>Telefono de contacto:</LabelPedido>
					<ValuePedido>{Capitalize(pedido.TelefonoContacto)}</ValuePedido>
				</Column>
				<Column>
					<LabelPedido>Fecha de carga:</LabelPedido>
					<ValuePedido>{Capitalize(pedido.Fecha)}</ValuePedido>
				</Column>{' '}
			</RowPedido>{' '}
			<RowPedido>
				<Column>
					<LabelPedido>Tipo de pedido:</LabelPedido>
					<ValuePedido>{Capitalize(pedido.TipoPedido.Descripcion)}</ValuePedido>
				</Column>

				<Column>
					<LabelPedido>Estado:</LabelPedido>
					<ValuePedido>{Capitalize(pedido.Estado.Descripcion)}</ValuePedido>
				</Column>
				<Column>
					<LabelPedido>Comentarios:</LabelPedido>
					<ValuePedido>{Capitalize(pedido.Comentarios)}</ValuePedido>
				</Column>
			</RowPedido>
		</Wrapper>
	);
};

export default DetallePedido;
