import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Steps } from "primereact/steps";
import { DataTable } from "primereact/datatable";
import ButtonVEME from "../../../components/ButtonVEME/ButtonVEME";
import { Column } from "primereact/column";
import { Divider } from "primereact/divider";
import InputVEME from "../../../components/InputVEME/InputVEME";
import { useDispatch, useSelector } from "react-redux";
import {
  startLoading,
  stopLoading,
} from "../../../redux/loader/loader.actions";
import {
  guardarNuevaReserva,
  obtenerOpcionesAmenity,
} from "../../../services/Amenities";
import { Checkbox } from "primereact/checkbox";
import { ToastContext } from "../../../context/ToastContext";

const Wrapper = styled.form`
  width: 100%;
  height: 100%;
`;

const Header = styled.div`
  height: 10%;
`;
const Body = styled.div`
  height: 30vh;
  @media screen and (max-width: 700px) {
		height: 90%;
	}
`;

const Row = styled.div`
  width: 100%;
  margin-bottom: 1.5em;
  display: flex;
  justify-content: ${(props) => props.justify};
`;

const Container = styled.div`
  height: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 700px) {
		width: 100%;
    height: 300px;
	}
`;

const TableContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const InputContainer = styled.div`
  width: 50%;
`;

const TyCAmenitites = styled.div`
  width: 100%;
  display: flex;
  font-size: 0.85em;
  align-items: center;
  justify-content: center;
  padding: 0.5em;
  margin-top: 0.5em;
`;
const TyCMessage = styled.span`
  color: ${(props) => props.theme.main};
  margin-left: 0.5em;
`;

const NuevaReserva = ({ onHide, amenities, ListarAmenities }) => {
  const dispatch = useDispatch();
  const { toast } = useContext(ToastContext);
  const { unidadfuncional, user } = useSelector((s) => s.auth);

  const [activeIndex, setActiveIndex] = useState(0);
  const [opciones, setOpciones] = useState([]);
  const [nuevaReserva, setNuevaReserva] = useState({
    amenity: null,
    fecha: new Date(),
    opcion: null,
    aceptaCargo: false,
    IdUnidadFuncional: unidadfuncional,
    telefono: user?.DATOS_PERSONA?.[0]?.CELULAR,
  });
  const items = [
    { label: "Amenity" },
    { label: "Fecha" },
    { label: "Opciones" },
  ];

  const onSubmit = async (e) => {
    e.preventDefault();
    if (activeIndex !== 2) {
      return;
    }
    try {
      dispatch(startLoading("Guardando reserva"));

      const response = await guardarNuevaReserva({
        ...nuevaReserva,
        fecha: nuevaReserva.fecha
          .toISOString()
          .split("T")[0]
          .split("-")
          .reverse()
          .join("/"),
        opcion: nuevaReserva.opcion?.id,
      });

      if (response.Success) {
        toast.current.show({
          severity: "success",
          summary: "Su reserva se generó correctamente",
        });
      } else {
        toast.current.show({
          severity: "error",
          summary: "Hubo un error al generar la reserva",
        });
      }
      ListarAmenities();

      setNuevaReserva({
        amenity: null,
        fecha: new Date(),
        opcion: null,
        aceptaCargo: false,
        IdUnidadFuncional: unidadfuncional,
        telefono: user?.DATOS_PERSONA?.[0]?.CELULAR,
      });
      onHide();
    } catch (error) {
      dispatch(stopLoading());
      toast.current.show({
        severity: "error",
        summary: "Hubo un error al generar la reserva",
      });
    }
  };

  const ListarOpcionesAmenity = async () => {
    dispatch(
      startLoading("Obteniendo opciones para el amenity y fecha seleccionada")
    );
    const response = await obtenerOpcionesAmenity({
      fecha: nuevaReserva.fecha
        .toISOString()
        .split("T")[0]
        .split("-")
        .reverse()
        .join("/"),
      IdAmenity: nuevaReserva.amenity?.id,
    });

    if (response.Success) {
      setOpciones(response.Data);
      opciones.map((opcion) => {
        opcion.cargoPorUso = "sin cargo";
        console.log(opcion);
      });
    } else {
    }

    dispatch(stopLoading());
  };

  useEffect(() => {
    if (activeIndex === 2) {
      ListarOpcionesAmenity();
    }
  }, [activeIndex]);

  return (
    <Wrapper onSubmit={onSubmit}>
      <Header>
        <Steps
          activeIndex={activeIndex}
          model={items}
          onSelect={(e) => setActiveIndex(e.index)}
          readOnly={false}
        />
      </Header>
      <Body>
        <Container>
          {activeIndex === 0 && (
            <TableContainer>
              <DataTable
                style={{ fontSize: "0.75em !important" }}
                selection={nuevaReserva.amenity}
                onSelectionChange={(e) =>
                  setNuevaReserva({ ...nuevaReserva, amenity: e.value })
                }
                dataKey="id"
                value={amenities}
              >
                <Column
                  selectionMode="single"
                  headerStyle={{ width: "3em" }}
                ></Column>

                <Column field="descripcion" />
              </DataTable>
            </TableContainer>
          )}{" "}
          {activeIndex === 1 && (
            <InputContainer>
              <InputVEME
                isCalendar
                label="Fecha de reserva"
                value={nuevaReserva.fecha}
                onChange={(e) =>
                  setNuevaReserva({ ...nuevaReserva, fecha: e.value })
                }
              />
            </InputContainer>
          )}
          {activeIndex === 2 && (
            <TableContainer>
              <DataTable
                selection={nuevaReserva.opcion}
                onSelectionChange={(e) =>
                  setNuevaReserva({ ...nuevaReserva, opcion: e.value })
                }
                dataKey="id"
                value={opciones}
              >
                <Column
                  selectionMode="single"
                  headerStyle={{ width: "3em" }}
                ></Column>

                <Column field="nombre" />
                <Column field="tipo" />
                {opciones.map((opcion) => {
                  if (opcion.cargoPorUso === "$ 0,00") {
                    opcion.cargoPorUso = "Sin Cargo";
                  }
                })}
                <Column field="cargoPorUso" />
              </DataTable>
              <TyCAmenitites>
                <Checkbox
                  checked={nuevaReserva.aceptaCargo}
                  onChange={(e) =>
                    setNuevaReserva({ ...nuevaReserva, aceptaCargo: e.checked })
                  }
                />

                <TyCMessage>
                  Acepto los términos, condiciones y cargo particular a mi
                  unidad, en caso de existir, por el uso de la misma.
                </TyCMessage>
              </TyCAmenitites>
            </TableContainer>
          )}
        </Container>
        <Row justify={"flex-end"}>
          {activeIndex > 0 && (
            <ButtonVEME
              small
              height="75%"
              secondary
              onClick={(e) => setActiveIndex(activeIndex - 1)}
              label="Volver"
              disabled={!nuevaReserva.amenity}
            />
          )}
          <Divider layout="vertical" />
          <ButtonVEME
            small
            height="75%"
            onClick={(e) =>
              activeIndex !== 2 ? setActiveIndex(activeIndex + 1) : null
            }
            label={activeIndex === 2 ? "Guardar" : "Siguiente"}
            disabled={
              activeIndex == 0
                ? !nuevaReserva.amenity
                : activeIndex == 2
                ? !nuevaReserva.opcion ||
                  (nuevaReserva?.opcion?.tiene_costo &&
                    !nuevaReserva.aceptaCargo)
                : false
            }
          />
        </Row>
      </Body>
    </Wrapper>
  );
};

export default NuevaReserva;
