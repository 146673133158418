import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../../components/Header/Header';
import ModuleNavigator from '../../components/ModuleNavigator/ModuleNavigator';
import { ToastContext } from '../../context/ToastContext';
import RoutesDashboard from '../../routes/RoutesDashboard';
import { ContainerDashboard, DashboardWrapper, RoutesContainer, TitleRoute, WrapperRoutes } from './styles';

const Dashboard = () => {
	const { auth } = useSelector((state) => state);
	const { toast } = useContext(ToastContext);
	const navigate = useNavigate();
	const location = useLocation();
	useEffect(() => {
		if (!auth.user) {
			toast.current.show({
				severity: 'error',
				summary: 'Tenes que iniciar sesión',
			});
			navigate('/');
		}
	}, []);

	const ROUTES = {
		'/principal': 'Mis expensas',
		'/principal/expensas': 'Mis expensas',
		'/principal/cobros': 'Cobros',
		'/principal/novedades': 'Novedades',
		'/principal/mi-administracion': 'Mi administración',
		'/principal/amenities': 'Amenities',
		'/principal/calendario-consorcio': 'Calendario del consorcio',
		'/principal/pedidos-sugerencias': 'Pedidos y sugerencias',
	};

	return (
		<DashboardWrapper>
			<Header />
			<ContainerDashboard>
				<ModuleNavigator />
				<WrapperRoutes>
					<TitleRoute>{ROUTES[location.pathname]}</TitleRoute>
					<RoutesContainer>
						<RoutesDashboard />
					</RoutesContainer>
				</WrapperRoutes>
			</ContainerDashboard>
		</DashboardWrapper>
	);
};

export default Dashboard;
