import { Link } from 'react-router-dom';
import styled from 'styled-components';
import image from '../../assets/images/authentication.svg';
export const AuthenticationWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${(props) => props.theme.main};
	background: linear-gradient(170deg, rgba(213, 40, 151, 1) 0%, rgba(68, 35, 96, 1) 100%);
`;

export const AuthenticationContainer = styled.div`
	background-color: white;
	width: 57.5%;
	height: 65%;
	border-radius: 0.75em;
	box-shadow: 0 0 0.75em ${(props) => props.theme.shadow50};
	display: flex;
	overflow: hidden;
	@media screen and (max-width: 700px) {
		width: 90%;
		height: 70%;
	  }
`;

export const WrapperLogoAuthentication = styled.div`
	height: 20%;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	@media screen and (max-width: 700px) {
		height: 20%;
		
	}
`;

export const LogoImage = styled.img`
	height: 35%;
	@media screen and (max-width: 700px) {
		height: 50%;
	}
`;

export const LoginWrapper = styled.form`
	width: 50%;
	height: 100%;

	display: flex;
	flex-direction: column;
	justify-content: center;
	@media screen and (max-width: 700px) {
		width: 100%;
	}
`;

export const LoginFormContainer = styled.div`
	width: 100%;
	height: 35%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-evenly;
	@media screen and (max-width: 700px) {
		height: 50%;
		width: 100%;
	  }
`;

export const RecoverPasswordWrapper = styled.form`
	width: 50%;
	height: 100%;

	display: flex;
	flex-direction: column;
`;

export const ImageWrapper = styled.div`
	width: 50%;
	height: 100%;
	background-image: url(${(props) => props.image});
	background-position: center;
	background-size: 85%;
	background-repeat: no-repeat;
	box-shadow: 0 0 1em ${(props) => props.theme.shadow25};
	@media screen and (max-width: 700px) {
		display: none;
	  }
`;

export const ButtonContainer = styled.div`
	height: 25%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

export const InputContainer = styled.div`
	width: 80%;
	@media screen and (max-width: 700px) {
		width: 90%;
	  }
`;

export const NavigateBetweenAuth = styled(Link)`
	font-size: 0.75em;
	color: ${(props) => props.theme.main};
	margin-top: 1em;
	font-weight: 500;
	transition: 0.2s;
	&:hover {
		color: ${(props) => props.theme.secondary};
		transition: 0.2s;
	}
`;

export const RecoverFormWrapper = styled.div`
	height: 50%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
