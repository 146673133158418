import { START_LOADING, STOP_LOADING } from './loader.actions';

const INITIAL_STATE = {
	isLoading: false,
	message: null,
};

export const LoaderReducer = (state = INITIAL_STATE, { payload, type }) => {
	switch (type) {
		case START_LOADING:
			return {
				...state,
				isLoading: true,
				message: payload,
			};
		case STOP_LOADING:
			return {
				...state,
				isLoading: false,
				message: null,
			};
		default:
			return state;
	}
};
