import React from 'react';
import styled from 'styled-components';
import { Dialog } from 'primereact/dialog';
import ButtonVEME from '../ButtonVEME/ButtonVEME';

const DefaultFooter = styled.div`
	width: 100%;
	height: 3em;
	display: flex;
	justify-content: flex-end;
`;

const Container = styled.div`
	width: ${(props) => props.width};
	height: ${(props) => props.height};
`;

const Modal = ({ draggable, visible, onHide, children, header, footer, height, width, ...rest }) => {
	return (
		<Dialog
			{...rest}
			visible={visible}
			onHide={onHide}
			header={header}
			draggable={Boolean(draggable)}
			footer={
				typeof footer === 'number'
					? null
					: footer || (
							<DefaultFooter>
								<ButtonVEME label='Guardar' />
							</DefaultFooter>
					  )
			}>
			<Container width={width} height={height}>
				{children}
			</Container>
		</Dialog>
	);
};

export default Modal;
