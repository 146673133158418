import { PrimeIcons } from 'primereact/api';
import React, { useContext } from 'react';
import styled from 'styled-components';
import Icon from '../../../components/Icon/Icon';
import { ToastContext } from '../../../context/ToastContext';

const WrapperDetalle = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	@media screen and (max-width: 700px) {
		font-size: 1em;
	}
`;

const DetalleRow = styled.div`
	width: 100%;
	display: flex;
	margin-bottom: 1em;
	justify-content: space-between;
	@media screen and (max-width: 700px) {
		font-size: 1em;
	}
`;

const DetalleColumn = styled.div`
	width: 25%;
	display: flex;
	flex-direction: column;
	color: ${(props) => props.theme.main};
	align-items: center;
	@media screen and (max-width: 700px) {
		font-size: 0.8em;
		width: 100%;
	}
`;

const LabelColumn = styled.span`
	font-size: 0.75em;
`;
const ValueColumn = styled.span`
	font-weight: bold;
	@media screen and (max-width: 700px) {
		text-align: center
	}
`;

const DetalleCobro = ({ cobro }) => {
	const { toast } = useContext(ToastContext);

	return (
		<WrapperDetalle>
			<DetalleRow>
				<DetalleColumn>
					<LabelColumn>Fecha de pago</LabelColumn>
					<ValueColumn>
						<span>{cobro.fecha}</span>
					</ValueColumn>
				</DetalleColumn>
				<DetalleColumn>
					<LabelColumn>A nombre de</LabelColumn>
					<ValueColumn>{cobro.persona}</ValueColumn>
				</DetalleColumn>
				<DetalleColumn>
					<LabelColumn>Comprobante Nro.</LabelColumn>
					<ValueColumn>{cobro.nroComprobante}</ValueColumn>
				</DetalleColumn>
			</DetalleRow>
			<DetalleRow>
				<DetalleColumn>
					<LabelColumn>Forma de pago</LabelColumn>
					<ValueColumn>{cobro.formaPagoDescripcion}</ValueColumn>
				</DetalleColumn>
				<DetalleColumn>
					<LabelColumn>Monto</LabelColumn>

					<ValueColumn>{cobro.monto}</ValueColumn>
				</DetalleColumn>
				<DetalleColumn>
					<LabelColumn>Estado</LabelColumn>

					<ValueColumn>{cobro.estado}</ValueColumn>
				</DetalleColumn>
			</DetalleRow>
			<DetalleRow>
				<DetalleColumn></DetalleColumn>
				<DetalleColumn></DetalleColumn>
				<DetalleColumn>
					<Icon
						as='a'
						href={cobro.comprobante}
						onClick={() => {
							toast.current.show({
								severity: 'info',
								summary: 'La descarga puede tardar unos segundos',
							});
						}}
						icon={PrimeIcons.FILE_PDF}
					/>
				</DetalleColumn>
			</DetalleRow>
		</WrapperDetalle>
	);
};

export default DetalleCobro;
