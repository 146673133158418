import React from 'react';
import styled from 'styled-components';
import { Capitalize } from '../../../utils/Functions';

const Wrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
`;

const Row = styled.div`
	width: 100%;
	margin-bottom: 1.5em;
	display: flex;
	justify-content: space-between;
`;

const LabelReserva = styled.span`
	font-size: 0.75em;
`;

const ValueReserva = styled.span`
	font-weight: bold;
`;

const Column = styled.div`
	width: 30%;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const DetalleReserva = ({ reserva }) => {
	return (
		<Wrapper>
			<Row>
				<Column>
					<LabelReserva>Amenitie reservada:</LabelReserva>
					<ValueReserva>{reserva.amenity}</ValueReserva>
				</Column>
				<Column>
					<LabelReserva>Codigo de reserva:</LabelReserva>
					<ValueReserva>{reserva.codigoReserva.toUpperCase()}</ValueReserva>
				</Column>
				<Column>
					<LabelReserva>Tipo:</LabelReserva>
					<ValueReserva>{Capitalize(reserva.opcionTipo)}</ValueReserva>
				</Column>
			</Row>
			<Row>
				<Column>
					<LabelReserva>Opción:</LabelReserva>
					<ValueReserva>{Capitalize(reserva.opcion)}</ValueReserva>
				</Column>
				<Column>
					<LabelReserva>Costo:</LabelReserva>
					<ValueReserva>{reserva.costo}</ValueReserva>
				</Column>
				<Column>
					<LabelReserva>Horario:</LabelReserva>
					<ValueReserva>
						De {reserva.fechaDesde.substring(10).trim()} a {reserva.fechaHasta.substring(10).trim()}
					</ValueReserva>
				</Column>
			</Row>
		</Wrapper>
	);
};

export default DetalleReserva;
