import { GetUserInformation, Login } from '../../services/Auth';

export const LOGIN_FAILED = 'LOGIN_FAILED';
export const RECEIVE_USER_DATA = 'RECEIVE_USER_DATA';
export const LOGOUT = 'LOGOUT';
export const SET_NEW_UF = 'SET_NEW_UF';

export const IniciarSesion = ({ username, password }) => {
	return async (dispatch) => {
		try {
			const response = await Login({ username, password });

			if (response.Token) {
				localStorage.setItem('authorization', response.Token);
				
				const { Data, Error } = await GetUserInformation();
				if (!Error) {
					dispatch(receiveUserData(Data));
					return Data;
				}
			}
		} catch (e) {
			dispatch({ type: LOGIN_FAILED, payload: { error: e } });
		}
	};
};

export const receiveUserData = (data) => {
	return {
		type: RECEIVE_USER_DATA,
		payload: data,
	};
};

export const Logout = () => {
	localStorage.removeItem('authorization');
	return {
		type: LOGOUT,
	};
};

export const setUnidadFuncionalSeleccionada = ({ ConsorcioId, UnidadFuncionalId }) => {
	return {
		type: SET_NEW_UF,
		payload: {
			ConsorcioId,
			UnidadFuncionalId,
		},
	};
};
