import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ButtonVEME from '../../../components/ButtonVEME/ButtonVEME';
import InputFile from '../../../components/InputFile/InputFile';
import InputVEME from '../../../components/InputVEME/InputVEME';
import { ToastContext } from '../../../context/ToastContext';
import { startLoading, stopLoading } from '../../../redux/loader/loader.actions';
import { informarNuevoPago, obtenerFormasPago } from '../../../services/Cobros';
import { InformarPagoFormulario, InputContainer, RowPagoFormulario } from './styles';

const InformarPago = ({ setModal, ListarCobros }) => {
	const dispatch = useDispatch();
	const { toast } = useContext(ToastContext);
	const { consorcio, unidadfuncional } = useSelector((state) => state.auth);

	const [formasPago, setFormasPago] = useState([]);

	const STATE_PAGO = {
		Fecha: new Date(),
		IdFormaPago: 1,
		NroComprobante: null,
		Monto: 0,
		Comentarios: '',
		Archivo: null,
	};

	const [nuevoPago, setNuevoPago] = useState(STATE_PAGO);

	const onSubmit = async (e) => {
		e.preventDefault();
		try {
			dispatch(startLoading('Informando pago'));
			const { Success, Data } = await informarNuevoPago({ ...nuevoPago, consorcio, unidadfuncional });
			if (Success) {
				toast.current.show({
					severity: 'success',
					summary: 'Pago informado correctamente',
				});
			}
			setModal({ visible: false });
			await ListarCobros();
			dispatch(stopLoading());
		} catch (error) {
			toast.current.show({
				severity: 'error',
				summary: 'Hubo un error al informar el pago',
			});
			dispatch(stopLoading());
		}
	};

	const ListarFormasPago = async () => {
		try {
			dispatch(startLoading('Obteniendo formas de pago disponibles'));
			const { Success, Data } = await obtenerFormasPago({ consorcio });
			if (Success) {
				setFormasPago(Data);
			}
			dispatch(stopLoading());
		} catch (error) {
			toast.current.show({
				severity: 'error',
				summary: 'Hubo un error al obtener las formas de pago',
			});
		}
	};
	useEffect(() => {
		ListarFormasPago();
	}, []);

	return (
		<InformarPagoFormulario onSubmit={onSubmit}>
			<RowPagoFormulario>
				<InputContainer>
					<InputVEME
						label='Fecha de pago'
						isCalendar
						value={nuevoPago.Fecha}
						onChange={(e) => setNuevoPago({ ...nuevoPago, Fecha: e.value })}
					/>
				</InputContainer>
				<InputContainer>
					<InputVEME
						label='Forma de pago'
						isDropdown
						options={formasPago}
						value={nuevoPago.IdFormaPago}
						optionValue={'Id'}
						optionLabel='Descripcion'
						onChange={(e) => setNuevoPago({ ...nuevoPago, IdFormaPago: e.value })}
					/>
				</InputContainer>
			</RowPagoFormulario>
			<RowPagoFormulario>
				<InputContainer>
					<InputVEME
						label='Monto abonado'
						isNumber
						value={nuevoPago.Monto}
						onChange={(e) => setNuevoPago({ ...nuevoPago, Monto: e.value })}
					/>
				</InputContainer>
				<InputContainer>
					<InputVEME
						label='Número de comprobante'
						value={nuevoPago.NroComprobante}
						onChange={(e) => setNuevoPago({ ...nuevoPago, NroComprobante: e.target.value })}
					/>
				</InputContainer>
			</RowPagoFormulario>
			<RowPagoFormulario margin='0'>
				<InputContainer>
					<InputVEME
						label='Comentarios'
						isTextarea
						value={nuevoPago.Comentarios}
						onChange={(e) => setNuevoPago({ ...nuevoPago, Comentarios: e.target.value })}
					/>
				</InputContainer>
				<InputContainer style={{ display: 'flex' }}>
					<InputFile
						label={
							nuevoPago.Archivo
								? `${nuevoPago.Archivo.name.substr(0, 15)}${
										nuevoPago.Archivo.name.length > 10 && '...'
								  }`
								: 'Comprobante'
						}
						onChange={(e) => {
							setNuevoPago({ ...nuevoPago, Archivo: e.target.files[0] });
						}}
						value={nuevoPago.Archivo}
					/>
				</InputContainer>
			</RowPagoFormulario>
			<RowPagoFormulario margin='3em 0em 0em 0em'>
				<InputContainer></InputContainer>
				<InputContainer style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<ButtonVEME
						label='Guardar'
						disabled={
							Object.keys(nuevoPago)
								.filter((key) => key !== 'Archivo' && key !== 'Comentarios')
								.filter((key) => !nuevoPago[key]).length
						}
					/>
				</InputContainer>
			</RowPagoFormulario>
		</InformarPagoFormulario>
	);
};

export default InformarPago;
