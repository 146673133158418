import { AxiosInstance } from '../utils/AxiosInstance';

export const getExpensas = async (ID) => {
	const { data } = await AxiosInstance.post(`/Servicios/ObtenerEstadoDeCuenta?idUF=${ID}`);

	return data;
};

export const getMovimientosLiquidacion = async (ID) => {
	const { data } = await AxiosInstance.post(`/Servicios/ObtenerMovimientos?IdLiquidacion=${ID}`);
	return data;
};
