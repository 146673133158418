import styled from 'styled-components';

export const AmenitiesWrapper = styled.div`
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
`;

export const HeaderAmenities = styled.div`
	height: 10%;
	width: 100%;
	display: flex;
	justify-content: flex-end;
`;

export const BodyAmenities = styled.div`
	width: 100%;
	height: 85%;
	display: flex;
	overflow: auto;
`;
