import styled from 'styled-components';

export const ExpensasWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
`;

export const HistoryExpenses = styled.div`
	height: 75%;
	width: 100%;
	overflow: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
`;
