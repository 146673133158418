import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { LogoImage } from '../../views/Authentication/styled';
import logo from '../../assets/images/logo.svg';
import InputVEME from '../InputVEME/InputVEME';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from 'primereact/menu';
import { PrimeIcons } from 'primereact/api';
import { Logout, setUnidadFuncionalSeleccionada } from '../../redux/auth/auth.actions';
import { useNavigate } from 'react-router-dom';
import { ToastContext } from '../../context/ToastContext';
import { startLoading, stopLoading } from '../../redux/loader/loader.actions';
import { getEstadoConsorcio } from '../../services/Consorcio';
import Modal from '../Modal/Modal';
import ModalCambiarContrasena from '../ModalCambiarContraseña/ModalCambiarContrasena';

const HeaderWrapper = styled.div`
	width: 100%;
	height: 8.5%;
	position: absolute;
	top: 0;
	left: 0;
	background-color: ${(props) => props.theme.white};
	box-shadow: 0 0 0.5em ${(props) => props.theme.shadow25};

	display: flex;
	align-items: center;
	justify-content: space-around;
`;

const InputContainer = styled.div`
	width: 25%;
`;

const RightHeader = styled.div`
	text-transform: capitalize;
`;

const UserDropdown = styled.span`
	font-weight: bold;
	cursor: pointer;
	display: flex;
	align-items: center;
`;

const IconDropdown = styled.i`
	transform: ${(props) => (props.isOpen ? `rotate(180deg);` : `rotate(0deg);`)};
	transition: 0.2s;
	margin-left: 0.25em;
`;

const Header = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { toast } = useContext(ToastContext);
	const { user } = useSelector((state) => state.auth);
	const { unidadfuncional, consorcio } = useSelector((state) => state.auth);

	const menu = useRef(null);
	const [isOpen, setIsOpen] = useState(false);
	const [persona, setPersona] = useState('');
	const [nuevaContrasena, setNuevaContrasena] = useState(false);

	const ObtenerEstadoAnimo = async () => {
		const response = await getEstadoConsorcio(consorcio);
	};

	useEffect(() => {
		if (user?.DATOS_PERSONA?.[0]) {
			const nombre = user?.DATOS_PERSONA[0]?.NOMBRE;
			const apellido = user?.DATOS_PERSONA[0]?.APELLIDO;
			setPersona(
				`
            ${nombre[0] + nombre.slice(1).toLowerCase()} ${apellido[0] + apellido.slice(1).toLowerCase()}
            `.trim(),
			);
		}
	}, []);

	useEffect(() => {
		ObtenerEstadoAnimo();
	}, []);

	return (
		<HeaderWrapper>
			<Modal
				onHide={() => setNuevaContrasena(false)}
				width={'25vw'}
				header='Cambio de contraseña'
				visible={nuevaContrasena}
				footer={NaN}>
				<ModalCambiarContrasena onHide={() => setNuevaContrasena(false)} />
			</Modal>
			<LogoImage src={logo} />
			<InputContainer>
				<InputVEME
					isDropdown
					options={user?.UNIDADES_FUNCIONALES}
					itemTemplate={(option) =>
						option && `${option.ConsorcioDescripcion} - ${option.UnidadFuncionalNombre}`
					}
					valueTemplate={(option) =>
						option && `${option.ConsorcioDescripcion} - ${option.UnidadFuncionalNombre}`
					}
					optionValue={'UnidadFuncionalId'}
					optionLabel='ConsorcioDescripcion'
					value={unidadfuncional}
					onChange={(e) => {
						const { ConsorcioId, UnidadFuncionalId } = user?.UNIDADES_FUNCIONALES.find(
							(uf) => uf.UnidadFuncionalId === e.value,
						);

						dispatch(
							setUnidadFuncionalSeleccionada({
								ConsorcioId,
								UnidadFuncionalId,
							}),
						);
					}}
				/>
			</InputContainer>
			<RightHeader>
				<UserDropdown onClick={(e) => menu.current.toggle(e)}>
					{persona} <IconDropdown isOpen={isOpen} className={PrimeIcons.ANGLE_DOWN}></IconDropdown>
				</UserDropdown>
				<Menu
					onHide={() => setIsOpen(false)}
					onShow={() => setIsOpen(true)}
					popup
					style={{ width: '17.5em' }}
					ref={menu}
					model={[
						{ label: `Nombre: ${user?.DATOS_PERSONA[0]?.NOMBRE}`, disabled: true },
						{ label: `Apellido: ${user?.DATOS_PERSONA[0]?.APELLIDO}`, disabled: true },
						{ label: `Email: ${user?.DATOS_PERSONA[0]?.EMAIL}`, disabled: true },
						{ label: `Celular: ${user?.DATOS_PERSONA[0]?.CELULAR}`, disabled: true },
						{ label: `DNI: ${user?.DATOS_PERSONA[0]?.DOCUMENTO}`, disabled: true },
						{
							separator: true,
						},
						{
							label: 'Cambiar contraseña',
							command: () => {
								setNuevaContrasena(true);
							},
						},
						{
							separator: true,
						},
						{
							label: 'Cerrar sesión',
							command: () => {
								dispatch(Logout());
								toast.current.show({
									severity: 'success',
									summary: 'Sesión cerrada correctamente.',
								});
								navigate('/');
							},
						},
					]}
				/>
			</RightHeader>
		</HeaderWrapper>
	);
};

export default Header;
